import { Box, styled } from '@material-ui/core'
import ReactPlayer from 'react-player'
import NoSSR from 'react-no-ssr'

export default function WhoWeAreContent() {
  const StyledBox = styled(Box)`
    ${({ theme }) => {
      return {
        [theme.breakpoints.down(1400)]: {
          width: '100%',
        },
      }
    }}
    padding: 1rem;
    padding-top: 0.5rem;
    width: 85%;
    margin: auto;
  `
  return (
    <>
      <StyledBox>
        <NoSSR>
          <Box sx={{ margin: 'auto', marginTop: '1rem' }}>
            <ReactPlayer
              style={{ margin: 'auto' }}
              controls
              loop
              width="100%"
              height="100%"
              url="https://tritagrugbypublic.s3.ap-southeast-2.amazonaws.com/TTR-who-we-are.mp4"
            />
          </Box>
        </NoSSR>
      </StyledBox>
    </>
  )
}
